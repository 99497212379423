.tenants-container {
  background-color: #eef1f6;
}
.action-icon {
  cursor: pointer;
  margin-right: 1rem;
}
.p {
  padding: 0 !important;
  margin: 0 !important;
}
.ant-card-body {
  padding: 5px;
}
.ant-card .ant-card-body {
  padding: 0;
}
.action-items-icon {
  font-size: 14px;
  margin-right: 12px;
  margin-top: 4px;
  color: #595959;
}
.action-items-icon-red {
  color: inherit;
}
.action-items {
  color: #252525;
  display: flex;
}

.action-items-red {
  color: red;
}
.action-items-red:hover {
  color: white;
}
.action-items-icon-red:hover {
  color: white !important;
}
.organization-heading {
  color: #1568c7;
  margin-left: 10px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

.add-organization-button{
  background-color: rgb(17, 95, 185);
  border: rgb(28, 86, 153);
  font-size: 13px;
  margin: 7px 8px 8px 5px;
}

.organization-container{
  height: 100%;
  padding: 24px;
}