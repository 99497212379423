.ant-menu-item.ant-menu-item-selected {
  font-weight: 700;
  color: #115fb9 !important;
  background-color: #f9fafb !important;
}
.ant-menu-item {
  font-weight: 400;
  border-right: none;
  margin-top: 5rem;
  color: black !important;
  background-color: EEF1F6 !important;
}

.menu-icon {
  margin-right: 10px;
  /* margin-left: -5px; */
}

.ant-layout.ant-layout-sider-trigger {
  background-color: #eef1f6 !important;
}

.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light {
  margin-top: 3px;
  border: none;
}

.ant-layout-sider-trigger {
  background-color: #eef1f6 !important;
  color: #274481 !important;
  font-weight: 700 !important;
  border-right: 2px solid #dedede !important;
  padding: 5px !important;
}
.demo-logo-vertical {
  display: flex;
  height: 72px;
  margin-left: 5px;
  align-items: center;
}
.demo-logo-vertical-collapsed {
  display: flex;
  height: 72px;
  align-items: center;
  justify-content: center;
}

.manager-heading {
  color: #115fb9;
  font-size: 14px;
  word-spacing: 3px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}
.license-heading {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
.collpase-icon-div {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-right: 11px;
}

.user-name {
  color: rgba(0, 0, 0, 0.8);
  font-size: 14px;
  margin-left: 7px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}
.user-role {
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  margin-left: 7px;
  line-height: 16px; /* 133.333% */
}

.side-nav-footer {
  padding: 15px 15px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f9fafb;
}

.settings-icon {
  cursor: pointer;
}
