.connection_container {
    width: 100%;
    height: 100vh;
    display: flex;
    background-position: center;
    background: url("Assets/images/AuthCover.png");
    background-repeat: no-repeat;
    background-size: cover;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    min-height: 600px;
  }
  
  .connection_container .left_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 200px;
  }

  .connection_container .left_container .main_heading {
    font-family: Roboto;
    font-size: 32px;
    font-weight: 700;
    line-height: 22px;
    text-align: left;
    color: #404040;    
    margin: 10px 0px;
  }
  
  .connection_container .left_container .logo {
    width: 231.9px;
    height: 79px;
    margin-bottom: 10px;
  }
  
  .connection_container .header {
    height: 80%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  
  .connection_container .right_container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: 300px;
  }
  
  .connection_container .right_container .connection_wrapper {
    background-color: #f5f6fa;
    min-height: 200px;
    padding: 0px !important;
  }
  .connection_container .right_container .connection_wrapper .stepper_container {
    padding: 16px 34px;
    background-color: white;
    display: flex;
    align-items: center;
    overflow: hidden;
    background: linear-gradient(89.36deg, #005ccc 9.5%, #4a9afc 113.63%);
  }
  
  .connection_container
    .right_container
    .connection_wrapper
    .stepper_container
    .heading {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    text-align: left;
    color: #ffffff;
    margin-left: 10px;
  }
  
  .connection_container
    .right_container
    .connection_wrapper
    .connection_form_wrapper {
    min-height: 63%;
    background-color: #f5f6fa;
    border-top: 1px solid #cccccf;
  }
  
  .connection_container .footer {
    padding-bottom: 8px;
    display: flex;
    justify-content: center;
    font-size: 12px;
    height: 20px;
  }
  
  .login_container {
    width: 100%;
  }
  
  .login_container .privacy-policy-container {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 15px 0px;
    border-top: 1px solid #0000001a;
  }
  
  .login_container .privacy-policy-container .text {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.41px;
    text-align: center;
    color: #00000073;
  }
  
  .login_container .privacy-policy-container .sub-text {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.41px;
    text-align: center;
    color: #1C71C0;
    cursor: pointer;
    margin-top: 8px;
  }
  
  .auth_logo_text {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
  }
  
  .connect-btn {
    border: 0px solid;
    border-radius: 0px;
    font-size: 13px !important;
    width: 100%;
    min-width: 100px;
    height: 40px;
    background-color: #1568c7 !important;
    color: white;
  }
  
  .auth-login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 5px;
    height: 150px;
    padding: 0px 34px;
  }
  
  .auth-login-container .login-btn-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 20px;
  }
  
  .auth-login-container .signup-btn {
    width: 100% !important;
    height: 50px !important;
    width: 90 !important;
    border: 1px solid #1568c7 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  
  .auth-login-container .signup-btn .text {
    font-size: 18px;
  }
  