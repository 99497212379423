@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: local('Roboto'), local('Roboto-Regular'), url('Assets/fonts/Roboto-Regular.ttf') format('truetype');
  }
  
  /* Import Roboto Italic */
  @font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    src: local('Roboto Italic'), local('Roboto-Italic'), url('Assets/fonts/Roboto-Italic.ttf') format('truetype');
  }
  
  /* Import Roboto Bold */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: local('Roboto Bold'), local('Roboto-Bold'), url('Assets/fonts/Roboto-Bold.ttf') format('truetype');
  }